import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { SRLWrapper } from 'simple-react-lightbox';

import Layout from '../components/layout';

export const query = graphql`
  query($id: String!) {
    wpGallery(id: { eq: $id }) {
      title
      gallery {
        galleryItems {
          id
          title
          localFile {
            id
            childImageSharp {
              thumb: gatsbyImageData(
                layout: CONSTRAINED,
                width: 500,
                placeholder: BLURRED,
                formats: [AUTO, WEBP, AVIF],
              )
              full: gatsbyImageData(
                layout: FULL_WIDTH
              )
            }
          } 
        }
      }
    }
  }
`;

const options = {
  buttons: {
    showDownloadButton: false,
  },
};

const WpGalleryTemplate = ({ data }) => {
  const images = data.wpGallery.gallery.galleryItems;
  const title = data.wpGallery.title;

  return (
    <Layout>
      <h1>{title}</h1>

      <SRLWrapper options={options}>
        <div className="masonry">
          {images.map((image) => (
            <Link key={image.localFile.id} to={image.localFile.childImageSharp?.full?.images?.fallback?.src} data-attribute="SRL">
              <GatsbyImage image={getImage(image.localFile.childImageSharp.thumb)} alt={image.title} />
            </Link>
          ))}
        </div>
      </SRLWrapper>
    </Layout>
  );
};

export default WpGalleryTemplate;
